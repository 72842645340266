import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./modules/login/login.module').then(m => m.LoginModule)
  },

  {
    path: 'signup',
    loadChildren: () =>
      import('./modules/signup/signup.module').then(m => m.SignupModule)
  },

  {
    path: 'privacy',
    loadChildren: () =>
      import('./modules/privacy/privacy.module').then(m => m.PrivacyModule)
  },
  {
    path: 'support',
    loadChildren: () =>
      import('./modules/support/support.module').then(m => m.SupportModule)
  },
  {
    path: 'unsubscribe',
    loadChildren: () =>
      import('./modules/unsubscribe/unsubscribe.module').then(
        m => m.UnsubscribeModule
      )
  },
  { path: 'onboarding', loadChildren: () => import('./modules/onboarding/onboarding.module').then(m => m.OnboardingModule) }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
