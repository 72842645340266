import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SignupRequest, SignupWaitinglistRequest, UnsubscribeRequest, VerifyWithPinRequest } from '../types/api-request.type';


@Injectable({
  providedIn: 'root'
})
export class DataService {
  apiPrefix: string;

  constructor(public http: HttpClient) {
    this.apiPrefix = environment.WAITINGLIST_BASE_API_URL;
  }

  getRequestHeadersOptions(): any {
    const requestHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return requestHeaders;
  }

  signupWaitinglist(payload: SignupWaitinglistRequest): Promise<any> {
    const options = this.getRequestHeadersOptions();
    return this.http
      .post(`${this.apiPrefix}/signup`, payload, {
        headers: options
      })
      .toPromise()
  }

  signup(payload: SignupRequest): Promise<any> {
    const options = this.getRequestHeadersOptions();
    return this.http
      .post(`${this.apiPrefix}/user`, payload, {
        headers: options
      })
      .toPromise()
  }

  verifySignup(payload: VerifyWithPinRequest): Promise<any> {
    const options = this.getRequestHeadersOptions();
    return this.http
      .post(`${this.apiPrefix}/verify`, payload, {
        headers: options
      })
      .toPromise()
  }

  unsubscribe(payload: UnsubscribeRequest): Promise<any> {
    const options = this.getRequestHeadersOptions();
    return this.http
      .delete(`${this.apiPrefix}/signup/${payload.token}`, {
        headers: options
      })
      .toPromise()
  }
}
