import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'flexly-why',
  templateUrl: './why.component.html',
  styleUrls: ['./why.component.scss']
})
export class WhyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
