<div class="section-header">Why Flexly?</div>
<div class="content-container">
  <div class="content">
    <img src="assets/lightning.svg" />
    <span>With other Flexly users, schedule meetings at the best possible time instantly.</span>
  </div>
  <div class="content">
    <img src="assets/clock.svg" />
    <span
      >With non-Flexly users, send a smart scheduling link and let your guest
      select a meeting time or propose alternatives.</span
    >
  </div>
  <div class="content">
    <img src="assets/comment.svg" />
    <span
      >Alternatively, create a poll within a second and see your guests vote in
      real time.</span
    >
  </div>
</div>
