import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'flexly-end',
  templateUrl: './end.component.html',
  styleUrls: ['./end.component.scss']
})
export class EndComponent implements OnInit {
  signupLoading: boolean;
  form: FormGroup;
  success = false;
  validationError = false;
  submitError = false;



  constructor(fb: FormBuilder, private dataService: DataService) {
    this.signupLoading = false;
    this.form = fb.group({
      name: [''],
      email: ['', [Validators.required, Validators.email]]
    });
  }

  ngOnInit(): void {
  }


  get email() {
    return this.form.get('email');
  }


  signupClicked() {

    if (this.form.valid) {
      this.signupLoading = true;
      this.validationError = false;
      this.submitError = false;

      this.dataService
        .signupWaitinglist({
          name: this.form.get('name').value,
          email: this.form.get('email').value,
        })
        .then(() => {
          this.signupLoading = false;
          this.form.reset();
          this.success = true;

        }).catch(() => {
          this.signupLoading = false;
          this.submitError = true;
        });
    } else {
      this.validationError = true;
    }
  }
}
