<div class="form-container" id="signup" #signup>
<div class="end-container">
  <ng-container *ngIf="!success">
  <p class="form-slogan">
    Join now and be part of a small scheduling revolution!
  </p>
  <form class="form-content" [formGroup]="form">
    <div class="form">
      <div class="inputs-container">
        <flexly-input
          class="input-name padding-bottom"
          formControlName="name"
          iconName="user"
          placeholder="Name"
        ></flexly-input>

        <flexly-input
          class=" input-email input-margin padding-bottom"
          formControlName="email"
          iconName="mail"
          placeholder="Email"
        ></flexly-input>
      </div>

      <button
        class="join-button margin-left-button"
        (click)="signupClicked()"
        [disabled]="signupLoading"
        >
        <ng-container *ngIf="!signupLoading">Join Now</ng-container>
        <ng-container *ngIf="signupLoading">Saving...</ng-container>
        </button
      >
    </div>
    <p class="privacy" *ngIf="validationError">
      Please enter a valid email address.</p>
    <p class="privacy" *ngIf="submitError">
        Oops, something went wrong. Please try in few minutes again.</p>
    <p class="privacy">
      I agree to Flexly's
      <a class="privacy" [routerLink]="['privacy']">Privacy Policy</a>
    </p>
  </form>
</ng-container>
  <ng-container *ngIf="success">
    <p class="form-slogan">
      Thank you!
    </p>
    <img class="hero-img" src="assets/weaselpus-plain.svg" />
  </ng-container>
</div>



</div>
