import { Component, OnInit } from '@angular/core';
import { PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';

@Component({
  selector: 'flexly-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss']
})
export class HeroComponent implements OnInit {
  isBrowser: boolean;
  constructor(@Inject(PLATFORM_ID) platformId: string,
  private scrollToService: ScrollToService) {
    this.isBrowser = isPlatformBrowser(platformId);
  }
  ngOnInit(): void {
  }
  triggerScrollTo() {

    const config: ScrollToConfigOptions = {
      target: 'signup'
    };

    this.scrollToService.scrollTo(config);
  }
}
