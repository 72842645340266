<div class="footer-row">
  <!-- <div class="footer-column">
    <span class="footer-header">Compare Flexly</span>
    <a class="link">Compare with calendly</a>
    <a class="link">Compare with doodle</a>
    <a class="link">Compare with x.ai</a>
  </div>
  <div class="footer-column">
    <span class="footer-header">Resources</span>
    <a class="link">How it works</a>
    <a class="link">FAQ</a>
  </div>
  <div class="footer-column">
    <span class="footer-header">About us</span>
    <a class="link">Privacy Guide</a>
    <a class="link">Security</a>
    <a class="link">Blog</a>
  </div> -->
</div>

<!-- <img class="social-media-icons" src="assets/social-links.svg" /> -->

<div class="bottom-links">
  <!-- <a class="link">Terms of Use</a> -->
  <!-- <span>-</span> -->
  <span class="tab">© 2020 by SND.AI LTD</span>
  <a class="link" routerLink="./privacy">Privacy Policy</a>
  <!-- <span>-</span> -->
  <!-- <a class="link">Cookie Settings</a> -->
</div>
<div class="bottom-links">

</div>
