<div class="hero-container">
  <div class="text-container">
    <div class="hero-header">Schedule with Flow</div>
    <div class="hero-sub-header">
      The first scheduling assistant that frees up time while maximizing everyone's productivity.
    </div>
    <div class="hero-content">
      Working preferences differ across teams and schedules - Flexly finds the best time based on everyone's daily flow.
    </div>
    <div>
      <ng-container *ngIf="isBrowser">
      <button class="join-button" (click)="triggerScrollTo()">Join Now</button>
    </ng-container>
      <ng-container *ngIf="!isBrowser">
        <a href="#signup">
          <button class="join-button">Join Now</button>
        </a>
      </ng-container>
      <!-- <div class="info-button">Only name and email needed</div> -->
    </div>
  </div>
  <img class="hero-img" src="assets/weaselpus-desk.svg" />
</div>

