
<!-- <div class="section-header">Plans & Pricing</div> -->
<div class="weaselpus-wrapper">
<img class="weaselpus-img" src="assets/weaselpus-plain.svg" />
</div>
  <div class="package-wrapper">

  <div class="package-container">
    <span class="package-header">Basic</span>
    <span class="package-sub-header">Free Forever</span>
    <div class="content-container">
      <span class="content-text"
        >Link up with your peers for instant booking.
      </span>
      <span class="content-text">Unlimited scheduling links and polls.</span>
      <span class="content-text"
        >Connect 1 calendar to avoid double booking.</span
      >
      <span class="content-text">Basic flexly profile.</span>
    </div>
  </div>

  <span class="middle-text">VS</span>

  <div class="package-container">
    <span class="package-header">Pro</span>
    <span class="package-sub-header">Free during beta</span>
    <div class="content-container">
      <span class="content-text"
        >Fine tuning of your day with flexly productivity controls.
      </span>
      <span class="content-text"
        >Connect unlimited calendars to keep work-life-balance in perfect
        harmony.</span
      >
      <span class="content-text"
        >Advanced flexly profile to experience perfect meeting times.</span
      >
    </div>
  </div>

</div>
